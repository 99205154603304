<style>
  .header_menu ul li a {
    color: rgba(255, 255, 255, 1);
    font-size: 13px;
    margin-right: 74px;
    height: auto !important;
  }

  .header_logo img {
    max-width: 100% !important;
    width: auto !important;
  }

  .header_logo {
    margin-left: 0px;
  }

  .header_section .row {
    align-items: center;
  }

  .header_menu ul {
    margin-bottom: 0px;
    padding: 0px;
  }

  /* .header_section {
        padding: 0px 30px;
    } */

  .header_menu ul li a {
    margin: 0px !important;
    padding: 10px;
  }

  .header_menu ul li {
    margin: 0px 5px;
  }

  .submenu_cls {
    float: left;
  }

  .header_sectionn {
    display: flex;
    align-items: center;
}
.header_logo_hold {
    width: 10%;
}
.header_menu_hold {
    width: 90%;
}
.header_menu {
    margin-left: 40px;
    text-align: left;
}

  .header_menu.marena_cls {
    float: left;
  }

  .breadcrumb-item.active {
    color: rgb(207, 214, 228);
    font-size: 20px;
    font-weight: 400;
  }

  .header_menu ul li a.nav-link.active {
    background-color: rgb(255 255 255);
    color: rgb(0, 0, 0);
    border-radius: 5px;
  }

  .header_menu ul li a.nav-link {
    cursor: pointer;
  }

  .header_menu ul li a {
    margin: 0px !important;
    padding: 2px 2px;
    line-height: 28px;
  }

  .header_logo {
    padding-left: 10px;
  }
  .register_menu button {
    font-size: 12px;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    margin-left: 15px;
    margin-top: 5px;
    padding: 5px 10px;
    outline: none;
  }

  .register_menu button.active {
    color: rgb(68 131 223) !important;
  }
</style>

<nav
  class="main-header navbar navbar-expand navbar-white navbar-light header_main"
>
  <!-- <nav class="main-header navbar navbar-expand navbar-white navbar-light header_main"> -->
  <div class="header_sectionn">
      <div class="header_logo_hold">
        <a class="header_logo"
          ><img
            src="/assets/images/mahe_logo.png"
            (click)="home()"
            class="img-responsive"
            alt="logo_img"
        /></a>
      </div>
      <div class="header_menu_hold">
        <div class="header_menu">
          <ul>
            <li
              *ngIf="
                mainAdminId == '99' ||
                adminClass == '100' ||
                adminClass == '101'
              "
            >
              <a
                class="nav-link"
                (click)="studentlist($event)"
                [ngClass]="{ active: activeclass == 'studentlist' }"
                >STUDENT LIST</a
              >
            </li>
            <li
              *ngIf="
                mainAdminId == '99' ||
                adminClass == '100' ||
                adminClass == '101'
              "
            >
              <a
                class="nav-link"
                (click)="stafflist($event)"
                [ngClass]="{ active: activeclass == 'stafflist' }"
                >STAFF LIST</a
              >
            </li>

            <li *ngIf="mainAdminId == '99'">
              <a
                class="nav-link"
                (click)="phd($event)"
                [ngClass]="{ active: activeclass == 'phd-list' }"
                >PHD LIST</a
              >
            </li>
             <!-- <li *ngIf="mainAdminId == '99' || adminClass == '115'">
              <a
                class="nav-link"
                (click)="agilereg($event)"
                [ngClass]="{ active: activeclass == 'agiledevops' }"
                > Agile and DevOps </a
              >
            </li>  -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '116'">
              <a
                class="nav-link"
                (click)="conferencereg($event)"
                [ngClass]="{ active: activeclass == 'conference' }"
                > Conference </a
              >
            </li>  -->

            <!-- <li *ngIf="mainAdminId == '99'|| adminClass == '117'">
              <a
                class="nav-link"
                (click)="hostels($event)"
                [ngClass]="{ active: activeclass == 'hostel-list' }"
                >Mahe Hostel</a
              >
            </li> -->
            <li *ngIf="mainAdminId == '99'|| adminClass == '118'">
              <a
                class="nav-link"
                (click)="daycare($event)"
                [ngClass]="{ active: activeclass == 'daycare-reg-list' }"
                >Day Care</a
              >
            </li>
             <li *ngIf="mainAdminId == '99' || adminClass == '119'">
              <a
                class="nav-link"
                (click)="eraya($event)"
                [ngClass]="{ active: activeclass == 'Falak' }"
                >Falak</a
              >
            </li>
            <!-- <li *ngIf="mainAdminId == '99'">
              <a
                class="nav-link"
                (click)="tmapaireg($event)"
                [ngClass]="{ active: activeclass == 'tmapai_reg_list' }"
                >TMA PAI</a
              >
            </li> -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '106'">
              <a
                class="nav-link"
                (click)="chessreg($event)"
                [ngClass]="{ active: activeclass == 'chess_event' }"
                >DIL AUR DIMAAG </a
              >
            </li> -->

            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '107'">
              <a
                class="nav-link"
                (click)="kalareg($event)"
                [ngClass]="{ active: activeclass == 'kala_fest' }"
                >KALA FEST</a
              >
            </li> -->
             <!-- <li *ngIf="mainAdminId == '99'|| adminClass == '109' ">
              <a
                class="nav-link"
                (click)="TDWFE($event)"
                [ngClass]="{ active: activeclass == 'TDWFE' }"
                >TDWFE</a
              >
            </li>  -->
            <!-- <li *ngIf="mainAdminId == '99'|| adminClass == '110' ">
              <a
                class="nav-link"
                (click)="law($event)"
                [ngClass]="{ active: activeclass == 'Construction-Law-blr' }"
                >Construction Law blr</a
              >
            </li>  -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '108'">
              <a
                class="nav-link"
                (click)="iqac($event)"
                [ngClass]="{ active: activeclass == 'iqac-mit' }"
                >IQAC</a
              >
            </li> -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '111'">
              <a
                class="nav-link"
                (click)="adhikshana($event)"
                [ngClass]="{ active: activeclass == 'Adhikshana' }"
                >Adhikshana</a
              >
            </li> -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '112'">
              <a
                class="nav-link"
                (click)="eraya($event)"
                [ngClass]="{ active: activeclass == 'Eraya' }"
                >Eraya</a
              >
            </li> -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '113'">
              <a
                class="nav-link"
                (click)="mujo($event)"
                [ngClass]="{ active: activeclass == 'Mujo' }"
                >Mujo</a
              >
            </li> -->
            <!-- <li *ngIf="mainAdminId == '99' || adminClass == '114'">
              <a
                class="nav-link"
                (click)="solstice($event)"
                [ngClass]="{ active: activeclass == 'Tech-Solstice-Nexus' }"
                >Tech Solstice Nexus</a
              >
            </li> -->
            

            <li>
              <a
                class="nav-link"
                (click)="marena($event)"
                *ngIf="(adminClass == '99' || adminClass == '100')"
                [ngClass]="{
                  active: (activeclass == 'marena' || activeclass == '')
                }"
                >MARENA</a
              >
            </li>

            <li>
              <a
                class="nav-link"
                (click)="bangloremarena($event)"
                *ngIf="adminClass == '99' || adminClass == '200'"
                [ngClass]="{
                  active: activeclass == 'bangloremarena'
                }"
                >BANGALORE MARENA</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                (click)="swim($event)"
                *ngIf="adminClass == '99' || adminClass == '101'"
                [ngClass]="{ active: activeclass == 'swim' }"
                >MIT SPORTS FACILTIES</a
              >
            </li>
            <!--<li><a class="nav-link" (click)="coursera($event)"  *ngIf="adminClass== '99' || adminClass== '102'"
                            [ngClass]="{ 'active':activeclass=='coursera'}">MAHE COURSERA</a></li>-->
            <li>
              <a
                class="nav-link"
                (click)="adhoc($event)"
                *ngIf="adminClass == '99' || adminClass == '103'"
                [ngClass]="{ active: activeclass == 'adhoc' }"
                >QUICK PAY</a
              >
            </li>
            <li>
              <a
                class="nav-link"
                (click)="almashine($event)"
                *ngIf="adminClass == '99' || adminClass == '104'"
                [ngClass]="{
                  active:
                    activeclass == 'almashine' || activeclass == 'coursera'
                }"
                >ALMASHINE</a
              >
            </li>
            <li><a class="nav-link" (click)="logout()">LOGOUT</a></li>
          </ul>
        </div>
      </div>
    
  </div>
</nav>
<nav
  class="main-header navbar navbar-expand navbar-white navbar-light header_main"
  *ngIf="
    (roleId == '100' ||
      roleId == '101' ||
      roleId == '102' ||
      roleId == '103' ||
      roleId == '104' ||
      roleId == '200') &&
    (activeclass == 'marena' ||
      activeclass == 'swim' ||
      activeclass == 'coursera' ||
      activeclass == 'adhoc' ||
      activeclass == 'almashine' ||
      activeclass == 'conference-list' ||
      activeclass == 'bangloremarena'
     )
  "
>
  <div class="header_section">
    <div class="row">
      <div class="col-sm-10">
        <div class="header_menu marena_cls">
          <ul *ngIf="activeclass != 'conference-list'">
            <li *ngIf="roleId == '100' || roleId == '101' || roleId == '103' || roleId == '200'">
              <a [routerLink]="['/']">Home</a>
            </li>
            <li *ngIf="roleId == '104' || roleId == '102'">
              <a
                [ngClass]="{
                  activess:
                    router.url === '/almashine-dashboard' ||
                    router.url === '/coursera-dashboard'
                }"
                (click)="almashine($event)"
                >Home</a
              >
            </li>
            <li *ngIf="roleId == '100' || roleId == '101' || roleId == '200'">
              <a [routerLink]="['/registration-list']">Registration List</a>
            </li>
            <li *ngIf="roleId == '104' || roleId == '102'">
              <a
                [ngClass]="{
                  activess:
                    router.url === '/almashine-registration-lists' ||
                    router.url === '/coursera-registration-lists'
                }"
                [routerLink]="['/almashine-registration-lists']"
                (click)="almashinePayment($event)"
                >Registration List</a
              >
            </li>

            <li *ngIf="roleId == '100' || roleId == '101' || roleId == '200'">
              <a [routerLink]="['/payment-list']">Payment Logs</a>
            </li>
            <li *ngIf="roleId == '104' || roleId == '102'">
              <a
                [ngClass]="{
                  activess:
                    router.url === '/payment-list' ||
                    router.url === '/coursera-payment-list'
                }"
                [routerLink]="['/payment-list']"
                (click)="almashinePayment($event)"
              >
                Payment Logs
              </a>
            </li>
            <li
              *ngIf="
               ( mainAdminId == '99' &&
                (roleId == '100' || roleId == '101') &&
                page != 'almashine')||(roleId == '200')
              "
            >
              <a [routerLink]="['/category-list']">Category</a>
            </li>
            <li *ngIf="roleId == '103'">
              <a [routerLink]="['/quickpay-category-list']">Category List</a>
            </li>
            <li *ngIf="roleId == '103'">
              <a [routerLink]="['/quickpay-payment-list']">Payment Logs</a>
            </li>
            <li *ngIf="roleId == '103'">
              <a [routerLink]="['/institution-list']">Institution List</a>
            </li>

            <!-- <li *ngIf="page=='almashine'"><a [routerLink]="['/almashine-registration-list']">Registration List</a></li>
                        <li *ngIf="page=='almashine'"><a [routerLink]="['/payment-list']">Payment Logs</a></li> -->
          </ul>

          <!-- <ul *ngIf="activeclass != 'tmapai_reg_list'">
            <li *ngIf="roleId == '100' || roleId == '101' || roleId == '103'">
              <a [routerLink]="['/']">Home</a>
            </li>
          
         
  

          
          </ul> -->

          <ul *ngIf="activeclass == 'conference-list'">
            <li><a [routerLink]="['/conference-list']">User List</a></li>
            <li>
              <a [routerLink]="['/conference-registration-list']"
                >Registration List</a
              >
            </li>

            <!-- <li><a [routerLink]="['/payment-list']">Payment Logs</a></li> -->

            <!-- <li *ngIf="page=='almashine'"><a [routerLink]="['/almashine-registration-list']">Registration List</a></li>
                        <li *ngIf="page=='almashine'"><a [routerLink]="['/payment-list']">Payment Logs</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="register_menu">
    <button
      [ngClass]="{ active: router.url == '/almashine-dashboard' }"
      (click)="almashine($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') && shouldDisplayAlmashineHome()
      "
    >
      Almashine
    </button>
    <button
      [ngClass]="{ active: router.url == '/coursera-dashboard' }"
      (click)="coursera($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') && shouldDisplayCourseraHome()
      "
    >
      Coursera
    </button>
  </div>
  <div class="register_menu">
    <button
      [ngClass]="{ active: router.url == '/almashine-registration-lists' }"
      [routerLink]="['/almashine-registration-lists']"
      (click)="almashinePayment($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') &&
        shouldDisplayAlmashineRegistration()
      "
    >
      Almashine Registration List
    </button>
    <button
      [ngClass]="{ active: router.url == '/coursera-registration-lists' }"
      [routerLink]="['/coursera-registration-lists']"
      (click)="courseraPayment($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') &&
        shouldDisplayCourseraRegistration()
      "
    >
      Coursera Registration List
    </button>
  </div>
  <div class="register_menu">
    <button
      [ngClass]="{ active: router.url == '/payment-list' }"
      [routerLink]="['/payment-list']"
      (click)="almashinePayment($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') &&
        shouldDisplayAlmashinePaymentLogs()
      "
    >
      Almashine Payment Logs
    </button>
    <button
      [ngClass]="{ active: router.url == '/coursera-payment-list' }"
      [routerLink]="['/coursera-payment-list']"
      (click)="courseraPayment($event)"
      *ngIf="
        (roleId == '104' || roleId == '102') &&
        shouldDisplayCourseraPaymentLogs()
      "
    >
      Coursera Payment Logs
    </button>
  </div>
</nav>
